<template>
  <FocusTrap>
    <div  id="cardwindow" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
      <h5 v-if="voucher.id == 0" class="card-title">Dip Entry Creation</h5>
      <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Dip Entry Updation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="row">

        <div class="col-md-3">

        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">
          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Tank</th>
              <th style="width:150px; text-align: right;">Dip</th>
              <th style="width:150px; text-align: right;">Qty</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody >
              <tr v-for="(detail,index) in voucher.list" >
                <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                <td style="padding: 0px;">
                  {{detail.tank.name}}
<!--                  <select class="form-control" v-if="detail" v-model="detail.tank.code" >-->
<!--                    <option v-for="tank in tanks" v-bind:value="tank.code">-->
<!--                      {{ tank.name }}-->
<!--                    </option>-->
<!--                  </select>-->
                </td>

                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Enter Dip Value" v-if="detail" v-model="detail.dip" />
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Enter Quantity" v-if="detail" v-model="detail.qty" @change="sumRowAmountTotal" />
                </td>
                <td style="padding: 0px;text-align: right">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
              <td style="padding: 0px;">
                <button style="visibility: hidden;" id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>

              <td class="text-right" style="padding: 0px;" ><span style="padding-right: 15px;" class="font-weight-semibold" >{{ indianFormat(rowAmountTotal) }}</span></td>

              <td></td>

            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>

        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <SaveButton @handle_save_action="saveVoucher"></SaveButton>
<!--          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>-->
        </div>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
import CloseButton from '../../../components/core/buttons/CloseButton'

export default {
  name: 'DipEntryForm',
  components: {
    CloseButton,
    SaveButton
  },
  store,
  data () {
    return {
      readonly: false,
      tanks:[],
      rowAmountTotal: 0.0,
      voucher: JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"cmp_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","ref_date":"0001-01-01","ref_no":"","remarks":"","list":[]}')
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"cmp_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","ref_date":"0001-01-01","ref_no":"","remarks":"","list":[]}')
    }
  },
  beforeMount () {
    const self = this;
    this.voucher = this.myvoucher;

    this.sumRowAmountTotal();
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
  },
  mounted () {
    const self = this;

    if(this.voucher.id == 0 ) {
      self.loadData();
    }


    if (self.$data.voucher.doc_date == '0001-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }

    $('#txtDate').focus();

  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2);
    },
    closeThis () {
      this.$emit('dipentry_window_closed');
      if (this.$data.voucher.id == 0) {
        this.$router.push('/');
      }else {
        this.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"cmp_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","ref_date":"0001-01-01","ref_no":"","remarks":"","list":[]}');
      }
    },
    addRow () {
      const self = this;
      self.$data.voucher.list.push(JSON.parse('{"tank":{"id":0,"name":""},"dip":0,"qty":0}'));

      setTimeout( ()=> {
        $('tbody>tr').last().find('td:eq(1) select').focus();
      }, 99);
    },
    sumRowAmountTotal () {
      const self = this;

      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.list.forEach(function (detail) {
        self.$data.rowAmountTotal += parseFloat(detail.qty);
      });

    },
    clear () {
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","locked":false,"type":0,"brn_id":0,"cmp_id":0,"finyear":0,"doc_date":"0001-01-01","doc_no":"","ref_date":"0001-01-01","ref_no":"","remarks":"","list":[]}');

      self.$data.voucher.list = [];
      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }


      // fetch the tanks
      self.$data.tanks = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/tanks/active`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.tanks = resp.data;

            self.$data.tanks.forEach((tank)=>{
              let detail = JSON.parse('{"tank":{"id":0,"name":""},"dip":0,"qty":0}');
              detail.tank= tank;

              self.$data.voucher.list.push(detail);
            });

          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      });

    },
    removeRow (index) {
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumRowAmountTotal();
      }
    },
    saveVoucher () {
      const self = this;

      try {

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));
        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.brn_id = parseInt(self.$store.state.user.branch.id);
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.ref_date).format('YYYY-MM-DD');

        myvoucher.list.forEach(function (detail) {
          detail.tank_id = parseInt(detail.tank.id);
          detail.item_id = parseInt(detail.tank.item_id);
          detail.qty = parseFloat(detail.qty);
          detail.dip = parseFloat(detail.dip);
        });

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#cardwindow').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        $('#txtDate').focus();
        fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/dipentry`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            self.$emit('dipentry_saved', resp.data);
            swal({
              title: 'Success', type: 'success', text: resp.msg, onClose: () => {
                $('#txtDate').focus()
              }, timer: 1500
            });


          } else {
            swal({
              title: 'Oops', text: resp.msg, type: 'error', onClose: () => {
                $('#txtDate').focus()
              }, timer: 1500
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oops', text: err.toString(), type: 'error', onClose: () => {
              $('#txtDate').focus()
            }, timer: 1500
          });
        }).finally(function () {
          $('#cardwindow').unblock();
        });
      }catch (e) {
        alert(e);
      }

    },

  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
